import React from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  styled,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DrugDeliveryTypeDto,
  PharmacyLocationDistanceOutput,
  ProviderOutput,
} from '@coverright/data-access/types/medicare';
import { GetDefaultPlansYear, getQuoteId } from '@coverright/utils';
import { colors } from '@coverright/ui/themes';
import { CRInfo } from '@coverright/ui/shared';
import { PharmaciesSelection } from '@coverright/features';
import {
  convertPreferredDrugInput,
  DoctorSelection,
  DrugSelection,
  PreferredDrugOutput,
  toPreferredDrugOutput,
} from '@coverright/widgets';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { QuoteContext } from '@coverright/shared/contexts';
import { ClientDrugDeliveryType } from '@coverright/data-access/types/enrollment';
import { ComparisonContext } from '../../ComparisonContext';

const EditPreferences = () => {
  const params = useParams<{ zip: string; countyName: string; tab?: string }>();
  const navigate = useNavigate();
  const quote = React.useContext(QuoteContext);
  const { authenticated } = React.useContext(KeycloakContext);
  const [tab, setTab] = React.useState(0);
  const {maPlans: {refetch: refetchPlansInComparison} } = React.useContext(ComparisonContext);

  React.useEffect(() => {
    if (params.tab) {
      if (params.tab === 'drugs') {
        setTab(1);
      } else if (params.tab === 'pharmacy') {
        setTab(2);
      }
    }
  }, [params.tab]);

  const [doctors, setDoctors] = React.useState<ProviderOutput[]>([]);
  React.useEffect(() => {
    setDoctors(quote?.preferredDoctors || []);
  }, [quote?.preferredDoctors]);

  const [drugs, setDrugs] = React.useState<PreferredDrugOutput[]>([]);
  React.useEffect(() => {
    setDrugs(quote?.preferredDrugs?.map(toPreferredDrugOutput) || []);
  }, [quote?.preferredDrugs]);

  const [pharmacies, setPharmacies] = React.useState<
    PharmacyLocationDistanceOutput[]
  >([]);
  const [orderType, setOrderType] = React.useState<DrugDeliveryTypeDto>();

  React.useEffect(() => {
    setOrderType(quote?.drugDeliveryType || undefined);
  }, [quote?.drugDeliveryType]);

  React.useEffect(() => {
    setPharmacies(quote?.preferredPharmacies || []);
  }, [quote?.preferredPharmacies]);

  const save = () => {
    const exec = (clientId: string) => {
      return quote
        .saveClientInfo({
          variables: {
            clientId,
            preferredDrugs: drugs.map(convertPreferredDrugInput),
            preferredPharmacies:
              orderType === DrugDeliveryTypeDto.Pharmacy
                ? pharmacies?.map((p) => p.npi)
                : [],
            drugDeliveryType: orderType as unknown as ClientDrugDeliveryType,
          },
        })
        .then(() =>
          quote.save({
            variables: {
              data: {
                preferredDoctors: doctors.map((d) => ({
                  npi: d.npi,
                  addresses: d.addresses.map((a) => ({ addressId: a.id })),
                })),
                planYear: quote.planYear || GetDefaultPlansYear(),
                county: params.countyName,
                zip: params.zip,
                id: getQuoteId(),
              },
            },
          })
        )
        .then(() => {
          if (!authenticated && !localStorage.getItem('createAccountShown')) {
            localStorage.setItem('showCreateAccountModal', 'true');
          }
        })
        .finally(() => {
          refetchPlansInComparison();
          goToPlans();
        });
    };

    if (quote?.clientId) {
      exec(quote?.clientId);
    } else {
      quote
        .save({
          variables: {
            data: {
              county: params.countyName,
              zip: params.zip,
              id: getQuoteId(),
            },
          },
        })
        .then((res: any) => exec(res.data?.saveMedicareQuote.clientId));
    }
  };

  const goToPlans = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth={'md'} sx={{ mb: 30 }}>
      <Button
        startIcon={<img src={'/assets/img/arrow-left.svg'} />}
        onClick={() => navigate(-1)}
      >
        Return to plans
      </Button>

      <Box sx={{ display: 'flex', gap: 4 }}>
        <Box>
          <Typography className={'fs-32 bold mb-24'}>
            Update your info
          </Typography>
          <StyledTabs
            sx={{ mb: 4 }}
            value={tab}
            onChange={(e, value) => setTab(value)}
          >
            <StyledTab
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 22 },
                      lineHeight: { xs: '20px', sm: '27px' },
                      fontWeight: 'inherit',
                    }}
                  >
                    Your doctors
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 12, sm: 14 },
                      lineHeight: { xs: '15px', sm: '17px' },
                      fontWeight: 'inherit',
                    }}
                  >
                    {doctors.length} added
                  </Typography>
                </Box>
              }
              value={0}
            />
            <StyledTab
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 22 },
                      lineHeight: { xs: '20px', sm: '27px' },
                      fontWeight: 'inherit',
                    }}
                  >
                    Your drugs
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 12, sm: 14 },
                      lineHeight: { xs: '15px', sm: '17px' },
                      fontWeight: 'inherit',
                    }}
                  >
                    {drugs.length} added
                  </Typography>
                </Box>
              }
              value={1}
            />
            <StyledTab
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 22 },
                      lineHeight: { xs: '20px', sm: '27px' },
                      fontWeight: 'inherit',
                    }}
                  >
                    Your pharmacy
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 12, sm: 14 },
                      lineHeight: { xs: '15px', sm: '17px' },
                      fontWeight: 'inherit',
                    }}
                  >
                    {pharmacies.length} added
                  </Typography>
                </Box>
              }
              value={2}
            />
          </StyledTabs>
          <TabPanel value={tab} index={0}>
            <Typography
              sx={{ color: colors.custom.green.variant2 }}
              className={'fs-18 lh-22 bold mb-32'}
            >
              Doctor networks vary by plan. Add any doctors you must see.
            </Typography>
            <DoctorSelection
              zip={params.zip!}
              value={doctors}
              onChange={setDoctors}
            />
            {!!doctors.length && <div className={'h-25'} />}
            <CRInfo
              open={true}
              description={
                <>
                  <strong>Check if your doctor is in-network.</strong> Copays or
                  coinsurance for your primary care physician and specialist
                  doctor visits can vary depending on whether your doctor is in
                  or out-of-network for any particular plan.{' '}
                </>
              }
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Typography
              sx={{ color: colors.custom.green.variant2 }}
              className={'fs-18 lh-22 bold mb-32'}
            >
              Enter any prescription drugs you need covered by your plan. Drug
              costs can vary significantly across different plans.
            </Typography>
            <DrugSelection value={drugs} onChange={setDrugs} />
            {!!drugs.length && <div className={'h-25'} />}
            <CRInfo
              open={true}
              description={
                <>
                  <strong>We may help you save.</strong> If you add a branded
                  drug, we’ll suggest a generic alternative and give you the
                  option to choose it instead, which may save your money.
                </>
              }
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Typography
              sx={{ color: colors.custom.green.variant2 }}
              className={'fs-18 lh-22 bold mb-32'}
            >
              Entering your pharmacy allows us to see if you’re eligible for
              lower cost-share.
            </Typography>
            <PharmaciesSelection
              zip={params.zip}
              value={pharmacies}
              onChange={setPharmacies}
              orderType={orderType}
              onOrderTypeChange={setOrderType}
            />
            <div className={'h-25'} />
            <CRInfo
              open={true}
              description={
                <>
                  <strong>
                    Choosing a pharmacy helps us more accurately estimate drug
                    costs.
                  </strong>{' '}
                  We’ll find your drug cost-shares depending on your pharmacy
                  type selection.
                </>
              }
            />
          </TabPanel>

          <Divider sx={{ borderColor: '#7B8F95' }} className={'mv-40'} />

          <Box
            sx={{
              display: 'flex',
              width: 1,
              gap: '12px',
              justifyContent: 'space-between',
              alignItems: { xs: 'center', md: 'flex-start' },
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <div>
              <Button
                variant={'contained'}
                size={'large'}
                color={'grey'}
                className={'medium w-150 fs-16'}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className={'w-300'}
                onClick={save}
                variant={'contained'}
                size={'large'}
                color={'primary'}
              >
                Update & return to plans
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default EditPreferences;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ boxSizing: 'border-box', maxWidth: 'calc(100% - 2px)' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    gap: 8,
    [theme.breakpoints.up('sm')]: {
      gap: 16,
    },
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'transparent',
  },
}));

interface StyledTabProps {
  label: any;
}

const StyledTab = styled((props: StyledTabProps & TabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 400,
  color: colors.text.primary,
  background: 'white',
  border: '1px solid #CCD8DC',
  borderRadius: 4,
  minHeight: 94,
  flex: 1,
  '&.Mui-selected': {
    fontWeight: 700,
    color: colors.text.primary,
    background: '#EEF4F1',
    border: '1px solid #1D9C80',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));
