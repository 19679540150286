import React from 'react';
import { usePlansForCompare } from '@coverright/features';
import { AppActionTypes, AppContext } from '@coverright/shared/contexts';
import { ComparablePlan, ComparablePlanType, PlanWithTiers } from '@coverright/data-access/medicare';
import { getClientId, getQuoteId, getStoredUser } from '@coverright/utils';

interface ComparisonContextState {
  isInCompare: (plan: PlanWithTiers) => boolean,
  removeFromCompare: (type: ComparablePlanType, id: string) => void,
  addToCompare: (plan: PlanWithTiers) => void,
  maPlans: {
    count: number,
    loading: boolean,
    called: boolean,
    plans: PlanWithTiers[],
    refetch: () => void,
  }
}

const defaultState: ComparisonContextState = {
  isInCompare: () => false,
  removeFromCompare: () => {},
  addToCompare: () => {},
  maPlans: {
    count: 0,
    loading: false,
    called: false,
    plans: [],
    refetch: () => {},
  }
};

export const ComparisonContext = React.createContext<ComparisonContextState>(defaultState);

export function ComparisonProvider(props: React.PropsWithChildren) {
  const [{state}, saveState] = React.useContext(AppContext);

  const save = (data: any) => {
    saveState({
      type: AppActionTypes.SAVE_STATE,
      payload: {
        stateKey: 'maSavedPlans',
        value: data.maSavedPlans
      }
    })
  }

  const {maPlans, isPlanSaved, removePlan, savePlan} = usePlansForCompare([state, save], getQuoteId(), getClientId());

  const isInCompare = (plan: PlanWithTiers) => {
    return isPlanSaved(toComparablePlan(plan, getStoredUser()?.zip, getStoredUser()?.countyName))
  }

  const addToCompare = (plan: PlanWithTiers) => {
    savePlan(toComparablePlan(plan, getStoredUser()?.zip, getStoredUser()?.countyName))
  }

  return <ComparisonContext.Provider value={{
    removeFromCompare: removePlan,
    addToCompare,
    isInCompare,
    maPlans,
  }}>
    {props.children}
  </ComparisonContext.Provider>

}

const toComparablePlan = (plan: PlanWithTiers, zip?: string | null, county?: string | null): ComparablePlan => ({
  zip: zip || '',
  county: county || '',
  bidId: plan.bidId,
  type: ComparablePlanType.Ma,
  planYear: plan.planYear,
})
