import { Typography } from '@mui/material';
import { StyledRating, TooltipedTitle } from '@coverright/ui/marketplaces';
import { GetDefaultPlansYear } from '@coverright/utils';
import React from 'react';
import { Plan, PlanYear } from '@coverright/data-access/types/medicare';

export default function PlanRating({plan}: {plan?: {planYear?: PlanYear | null, rating?: number | null, ratingNote?: string | null}}) {
  const planYear = React.useMemo(() => {
    if (plan?.planYear) {
      return plan.planYear.toString().replace('Year', '')
    }
    return GetDefaultPlansYear().toString().replace('Year', '');
  }, [plan])

  if (!plan) {
    return null;
  }

  const rating = !plan.rating ? <Typography className={'fs-12 no-wrap'}>{plan.ratingNote}</Typography>
    : <StyledRating size={'small'} precision={0.5} readOnly defaultValue={plan.rating || 0}/>;

  return <TooltipedTitle
    title={<div className={'flex flex-align-center'}>
      {rating}
      {!!plan.rating && <Typography sx={{ml: 1}} className={'medium fs-12'}>({planYear})</Typography>}
    </div>}
    tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}
    imgProps={{
      className: `w-16 h-16`,
    }}/>;
}
