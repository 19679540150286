import { Box, Button, Container, Typography } from '@mui/material';
import PlanCard from '../plan-list/components/PlanCard';
import { CRPreloader } from '@coverright/ui/shared';
import React from 'react';
import { GetDefaultPlansYear, getQuoteId } from '@coverright/utils';
import FavoritesSideContent from './FavoritesSideContent';
import { useMedicarePlans } from '@coverright/data-access/medicare';
import { FavoritesContext, QuoteContext } from '@coverright/shared/contexts';
import { Field, Plan } from '@coverright/data-access/types/medicare';
import { Sort } from '@coverright/data-access/types/medigap';
import ComparisonPanel from '../plan-comparison/components/ComparisonPanel';
import { useNavigate, useParams } from 'react-router';

export function Favorites() {
  const {zip, countyName} = useParams<{ zip: string, countyName: string }>();
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const {id, preferredDrugs, planYear} = React.useContext(QuoteContext);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const navigate = useNavigate();

  const [request, results] = useMedicarePlans({
    onCompleted: response => {
      setPlans([...plans, ...response.plans.data])
    }
  });

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const quoteId = React.useMemo(() => {
    return id || getQuoteId();
  }, [id])

  React.useEffect(() => {
    if (zip && countyName) {
      request({
        variables: {
          filter: {onlyFavorite: true, zip, countyName},
          page: {
            page: 0,
            size: 20
          },
          quoteId,
          sort: [
            { "field": Field.Premium,"direction":Sort.Asc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ]
        }
      })
    }
  }, [zip, countyName]);


  return (<Container maxWidth={'lg'} sx={{mb: 30}}>
    <Button
      startIcon={<img src={"/assets/img/arrow-left.svg"}/>}
      onClick={() => navigate(-1)}>
      Back to plans
    </Button>

    <Typography sx={{fontSize: {xs: 20, sm: 32}, lineHeight: {xs: '25px', sm: '40px'}}}>Plans added to favorites <b>({plans.length})</b></Typography>

    <Box sx={{display: 'flex', gap: 4, flexDirection: {xs: 'column-reverse', lg: 'row'}}}>
      <FavoritesSideContent />

      <Box flexDirection={'column'}>
        <Box>
          {!!plans.length && plans.map(plan => (
            <PlanCard plan={plan}
                      favorites={favorites}
                      toggleFavorite={toggleFavorite}
                      preferredDrugs={preferredDrugs}
                      zip={zip!}
                      planYear={planYear || GetDefaultPlansYear()}
                      countyName={countyName!}
                      key={plan.bidId} />
          ))}
          {(!plans.length && !results.loading && results.called) && <Typography color={'textPrimary'}
                                                                                align={'center'}
                                                                                className={'fs-18 bold mt-50 mb-110'}>
            You don’t have any plans added to favorites. When reviewing plans, click the heart icon to save a plan that you like to your favorites.
          </Typography>}
          {results.loading &&
            <Box textAlign={"center"} my={2}>
              <CRPreloader />
            </Box>
          }
        </Box>
      </Box>
    </Box>
    {zip && <ComparisonPanel countyName={countyName!} zip={zip} />}
  </Container>);
}

export default Favorites;
